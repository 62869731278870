<template>
  <div>
    <b-card
      class="mb-4"
    >
      <b-card-text>
        <b-row>
          <b-col
            cls="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <b-form-select
              v-model="source"
              :options="sourceFilterOptions"
              class="width-15-per mr-1"
              @change="reloadPage()"
            />
            <b-button
              variant="primary"
              class="btn-icon"
              @click="reloadPage()"
            >
              <feather-icon icon="RotateCwIcon" />
            </b-button>
            <b-button
              variant="primary"
              class="btn-icon ml-1"
              @click="resetPage()"
            >
              Reset
            </b-button>
            <label class="ml-1">Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-spinner
              v-if="show"
              class="ml-1"
              label="Loading..."
            />
          </b-col>
          <!-- Search -->
          <b-col
            cls="12"
            class="mt-2"
          >
            <!-- input search -->
            <vue-good-table
              id="reqTbl"
              :columns="columns"
              :rows="itemList"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: filterQ,
                trigger: 'enter',
                skipDiacritics: true,
              }"
              :pagination-options="{
                enabled: false,
                perPage:perPage
              }"
              striped
              responsive
              hover
              theme="polar-bear"
              mode="remote"
              @on-row-click="onRowSelected"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <div
                  v-if="props.column.field === 'source'"
                >
                  <span
                    v-if="props.row.source == 'A'"
                    class="text-nowrap"
                  >API</span>
                  <span
                    v-if="props.row.source == 'E'"
                    class="text-nowrap"
                  >Event</span>
                </div>
                <div
                  v-else-if="props.column.field === 'created_on'"
                >
                  <span class="text-nowrap">{{ formatDateTime(props.row.created_on) }} </span>
                </div>
                <!-- Column: Actions -->
                <span v-else-if="props.column.field === 'actions'">
                  <a @click="onRowSelected({ row:{id: props.row.id} })">
                    <feather-icon icon="InfoIcon" />
                    <span class="align-middle ml-50">Info</span>
                  </a>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div />
                </div>
              </template>
            </vue-good-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0 mt-2"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @click="goToPage()"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-sidebar
      id="request-log-sidebar-right"
      v-model="requestLogEditVisible"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      :style="cssVars"
    >
      <request-log-sidebar
        v-if="requestLogEditVisible === true"
        :request-log-id="selectedRowId"
        :is-request-log-sidebar-active.sync="requestLogEditVisible"
        :visible="isRequestLogSidebarActive"
        @clicked="onClickChild"
      />
    </b-sidebar>

  </div>
</template>

<script>
// import router from '@/router'
import {
  // BTable,
  // BFormCheckbox,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  // BAvatar,
  // BBadge,
  // BFormInput,
  BPagination,
  VBToggle,
  // BLink,
  BSpinner,
  BFormSelect,
  // BFormGroup,
  BSidebar,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import RequestLogSidebar from './requestLogSidebar.vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { requestLogs } from './requestLogs'

// import router from "@/router";

export default {
  name: 'SearchList',
  components: {
    VueGoodTable,
    // BTable,
    // BFormCheckbox
    BSidebar,
    BCard,
    BCardText,
    BRow,
    BCol,
    // BBadge,
    // BAvatar,
    vSelect,
    // BFormInput,
    BPagination,
    // BLink,
    BSpinner,
    BFormSelect,
    // BFormGroup,
    BButton,
    RequestLogSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      requestLogEditVisible: false,
      source: '',
      sourceFilterOptions: [
        { value: '', text: 'Any' },
        { value: 'e', text: 'Event' },
        { value: 'a', text: 'API' },
      ],
      keyTyping: false,
      timerCount: 0,
      columnFilters: {},
      columnSort: {
        type: 'desc',
        field: 'id',
      },
      dir: false,
      searchTerm: '',
      filterQ: '',
      isRequestLogSidebarActive: false,
      refetchData: '',
      show: true,
      itemList: [],
      hasResult: true,
      requestLogSidebarWidth: '70%',
      showMoreInfoVisible: false,
      selectedRowId: '',
      selected: [],
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Id',
          },
          tdClass: 'text-left',
          width: '120px',
        },
        {
          label: 'Action',
          field: 'action',
          filterOptions: {
            enabled: true,
            placeholder: 'Search action',
          },
          tdClass: 'text-left',
          width: '350px',
        },
        {
          label: 'Source',
          field: 'source',
          filterOptions: {
            enabled: true,
            placeholder: 'Search source',
          },
          tdClass: 'text-left',
          width: '150px',
        },
        {
          label: 'Response Code',
          field: 'response_code',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search code',
          },
          tdClass: 'text-left',
          width: '175px',
        },
        {
          label: 'Created on',
          field: 'created_on',
          filterOptions: {
            enabled: true,
            placeholder: 'Search date',
          },
          tdClass: 'text-left',
          width: '175px',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'text-right',
          width: '105px',
        },
      ],
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 5,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    cssVars() {
      return {
        '--max-width': this.requestLogSidebarWidth,
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    isRequestLogSidebarActive() {
      this.reloadPage()
    },
    currentPage() {
      this.reloadPage()
    },
    perPage() {
      this.reloadPage()
    },
  },
  mounted() {
    this.reloadPage()
    this.requestLogSidebarWidth = '50%'
  },
  beforeCreate() {},
  created() {
    // eslint-disable-next-line no-unused-vars
    this.keyTyping = false
    window.addEventListener('keydown', e => {
      this.keyTyping = true
      if (e.key === 'Enter') {
        this.keyTyping = false
        // this.reloadPage()
      }
    })
  },
  methods: {
    // ${row.id}
    resetPage() {
      this.source = ''
      this.reloadPage()
    },
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    onColumnFilter(params) {
      this.columnFilters = {
        id: params.columnFilters.id,
        action: params.columnFilters.action,
        source: params.columnFilters.source,
        created_on: params.columnFilters.created_on,
        response_code: params.columnFilters.response_code,
      }
      if (this.keyTyping === false) {
        this.reloadPage()
      }
    },
    onSortChange(params) {
      this.columnSort = {}
      this.columnSort.direction = params[0].type
      this.columnSort.field = params[0].field
      this.reloadPage()
      // console.log(params[0])
      // params[0].sortType - ascending or descending
      // params[0].columnIndex - index of column being sorted
    },
    backToReqList() {
      this.filterQ = ''
      this.columnFilters = {}
      this.reloadPage()
    },
    onRowSelected(row) {
      this.selectedRowId = row.row.id
      this.requestLogEditVisible = true
    },
    onCheckboxSelected(row) {
      this.selected = row.id
    },
    formatDateTime(date) {
      return ` ${new Date(date).toISOString().slice(0, 10)} ${new Date(date).toISOString().slice(11, 16)} `
    },
    reloadPage() {
      this.show = true
      // this.itemList = []
      // const searchTerm = router.currentRoute.params.q
      const searchTerm = this.filterQ
      const searchData = {
        source: this.source, columnFilters: this.columnFilters, columnSort: this.columnSort, search: searchTerm, itemsPerPage: this.perPage, currentPage: this.currentPage,
      }
      // itemList = [{'id':111}]
      const { fetchRequestLogs } = requestLogs()
      fetchRequestLogs(searchData)
        .then(response => {
          if (response.data.data.total_records > 0) {
            this.hasResult = true
            this.itemList = response.data.data.failed_request_log
            this.totalRows = response.data.data.total_records
          } else {
            this.hasResult = false
            this.itemList = []
          }
          this.show = false
        })
    },
    onClickChild(value) {
      if (value) {
        this.reloadPage()
      } else if (value === 'more-info-bt') {
        this.requestLogSidebarWidth = '100%'
        this.showMoreInfoVisible = true
      } else if (value === 'few-info-bt') {
        this.requestLogSidebarWidth = '30%'
        this.showMoreInfoVisible = false
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-good-table.scss';
@import '~vue-good-table/src/styles/style.scss';
#request-log-sidebar-right {
  width: var(--max-width);
  //max-width: var(--max-width);
}
#reqTbl .vgt-table tr td {
  vertical-align: middle;
  padding-top: 5px;
  line-height: 37px;
}
</style>
