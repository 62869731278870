<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
    >
      <b-card
        class="mb-4"
      >
        <b-card-header>
          <h3>Request Log
            <b-spinner
              v-if="requestLogId>0 && requestLogData.action === ''"
              class="ml-2"
              label="Loading..."
            />
          </h3>
        </b-card-header>
        <b-card-body v-if="requestLogData.action !== ''">
          <b-row class="m-0">
            <b-col
              lg="12"
            >
              <b-list-group flush>
                <b-list-group-item>
                  Action: {{ requestLogData.action }}
                </b-list-group-item>
                <b-list-group-item>
                  Request: {{ requestLogData.request }}
                </b-list-group-item>
                <b-list-group-item>
                  Response: {{ requestLogData.response }}
                </b-list-group-item>
                <b-list-group-item>
                  Response Code: {{ requestLogData.response_code }}
                </b-list-group-item>
                <b-list-group-item>
                  Source: {{ getSourceText }}
                </b-list-group-item>
                <b-list-group-item>
                  Created On: {{ requestLogData.created_on }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" offset-md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1 mt-2"
                @click="closeSidebar()"
              >
                Close
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard, BCardBody, BCardHeader,
  BOverlay, BSpinner, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'

import {
  required,
} from '@validations'

import { requestLogs } from '@/views/pages/debugger/requestLogs'

export default {
  name: 'RequestLogSidebar',
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BOverlay,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isRuleSidebarActive', 'requestLogId'],
    event: 'update:is-request-log-sidebar-active',
  },
  props: {
    requestLogId: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      show: true,
      errorsMessage: '',
      required,
      requestLogData: {
        action: '',
      },
    }
  },
  computed: {
    getSourceText() {
      if (this.requestLogData.source === 'A') {
        return 'API'
      } if (this.requestLogData.source === 'E') {
        return 'Event'
      }
      return '-'
    },
  },
  watch: {
    // eslint-disable-next-line
    getRequestLog(requestLogId) {
      if (requestLogId) {
        this.show = true
        this.requestLogData = {}
        const { fetchRequestLog } = requestLogs()
        fetchRequestLog(requestLogId)
          .then(response => {
            this.requestLogData = response.data.data
            this.requestLogData.created_on = this.formatDate(this.requestLogData.created_on)
            this.show = false
          })
      }
    },
  },
  mounted() {
    this.show = false
    if (this.requestLogId > 0) {
      this.requestLogData = { action: '' }
      this.requestLogData.can_edit_or_delete = false
      const { fetchRequestLog } = requestLogs()
      fetchRequestLog(this.requestLogId)
        .then(response => {
          this.requestLogData = response.data.data
          this.requestLogData.created_on = this.formatDate(this.requestLogData.created_on)
          this.show = false
        })
    }
  },
  methods: {
    closeSidebar() {
      this.$emit('update:is-request-log-sidebar-active', false)
      this.$emit('update:refetch-data', true)
      this.show = false
    },
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    onClickChild(value) {
      this.$emit('clicked', value)
    },
  },
}
</script>
