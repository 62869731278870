import store from '@/store'
/* eslint-disable import/prefer-default-export */
export const requestLogs = () => {
  // eslint-disable-next-line arrow-body-style
  const fetchRequestLogs = payload => {
    return store.dispatch('request-logs/fetchRequestLogs', payload)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchRequestLog = reqId => {
    return store.dispatch('request-logs/fetchRequestLog', reqId)
  }

  return {
    fetchRequestLogs,
    fetchRequestLog,
  }
}
